export class BackendUserModel {
    id!: number;
    password: any;
    remember_token: any;
    account_id: any;
    backend_user_id: any;
    first_name: any;
    last_name: any;
    mobile: any;
    email: any;
    avatar: any;
    user_role_id: any;
    type: any;
    abn: any;
    website: any;
    business_phone: any;
    status: any;
    company_name: any;
    business_role: any;
    signup_themeselves: any;
    customer_code: any;
    guid: any;
    email_verified_at: any;
    role: any;
    fullName: any;
}
